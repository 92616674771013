// TODO: There are some methods here that are duplicated from other files.
// We should refactor all our PDF downloads flows to reuse the same helper methods
import { jsPDF, RGBAData } from 'jspdf';
import 'svg2pdf.js';
import {
    AnalysisProfile,
    getProfileUnit,
} from '../../../enums/AnalysisProfile';
import { toggleLayerVisibilityBySource } from '../../../helpers/createPdfMap';
import { formatValue } from '../../../helpers/ValueFormatter';
import ValueFormat from '../../../enums/ValueFormat';
import getLibraryJson from '../../../helpers/getLibraryJson';
import {
    FeatureCollection,
    GeoJsonProperties,
    Point as GeoJsonPoint,
} from 'geojson';
import {
    ColorRGB,
    ExportSettings,
    FacilityCommonFilter,
    FacilityInfo,
    MapQueriedPointsBySurveyByUda,
    MapQueryResponse,
    PdfReportTypographyClass,
    Point,
    PricingTrends,
    Profile,
    SectionVisualizationLabel,
    SiteAnalysis,
    SiteAnalysisBase,
    SiteAnalysisExecutiveSummary,
    SiteAnalysisExecutiveSummaryDetailed,
    SiteAnalysisFacility,
    SiteAnalysisRentalCompsIndividual,
} from '../../../types';
import {
    GRAY10,
    GRAY20,
    GRAY40,
    GRAY50,
    GREEN,
    RED,
} from '../../../helpers/pdfColorsRGB';
import {
    ADDRESS,
    H1,
    H1_SMALLER,
    H2,
    H3,
    H4,
    H5,
    LABEL,
    LIST_ITEM,
    MICRO_TEXT,
    MICRO_TEXT_BOLD_LIGHT,
    MICRO_TEXT_LIGHT,
    SELECTION,
    SMALL_TEXT,
    SMALL_TEXT_70_SB,
    SMALL_TEXT_BLACK,
    SMALL_TEXT_DARK,
    SMALL_TEXT_DARK_BOLD,
    SUBTITLE,
    TINY_TEXT,
    TINY_TEXT_70,
    TINY_TEXT_BOLD_BLACK,
    TINY_TEXT_BOLD_LIGHT,
    TINY_TEXT_DARK,
    TINY_TEXT_ITALIC_LIGHT,
    TINY_TEXT_LIGHT,
    VALUE_STRONG,
} from '../../../helpers/pdfTypographyClass';
import {
    addressIcon,
    barlowCondensedBoldFont,
    cover,
    coverPin,
    mapPinImage,
    mapPinImageOpaque,
    marketSummaryIconImage,
    montserratBoldFont,
    montserratRegularFont,
    montserratSemiBoldFont,
    phoneIcon,
    sourceSansProBoldFont,
    sourceSansProItalicFont,
    sourceSansProRegularFont,
    sourceSansProSemiBoldFont,
    tractiqLogoFullImage,
    tractiqLogoLightImage,
    websiteIcon,
} from '../../../assets/report/assets-loader';
import createPdfMap from '../../../helpers/createPdfMap';
import { PDF_REPORT } from './getReportDefitintion';
// Page layout dimensions in points
const FULL_WIDTH = 216;
const FULL_HEIGHT = 280;
let X_MARGIN: number;
let Y_MARGIN: number;
let BASE: number;
let CONTENT_WIDTH: number;
let CONTENT_HEIGHT: number;

// PDF document pointer
let doc: jsPDF;
let currentDate: {
    [key: string]: string;
};

let baseImages: {
    [key: string]:
        | string
        | HTMLImageElement
        | HTMLCanvasElement
        | Uint8Array
        | RGBAData;
};

// Helper draw methods interface definition
type RenderOptions = {
    reportTitle?: string;
    address?: string;
    image?: any;
    imageRatio?: number;
    selection?: number[];
    profile?: Profile;
};
const tractiqLogoFullRatio = 1087 / 227;
// Load images
export async function getPdfReport(
    exportSettings: ExportSettings,
    address: string,
    selection: number[],
    profile: Profile,
    downloadPdfWithPagination: boolean,
    point: Point,
    summaryData: SiteAnalysis[],
    executiveSummaryDetailed: SiteAnalysis[],
    pointsBySurveyByUda: MapQueriedPointsBySurveyByUda,
    geojson: FeatureCollection<GeoJsonPoint, GeoJsonProperties>,
) {
    doc = new jsPDF({
        format: 'letter',
    });
    if (!summaryData) return;
    const { documentProperties } = exportSettings;
    documentProperties.subject = exportSettings.documentSubject;
    const reportTitle: string =
        documentProperties.alternativeTitle || 'New Report';
    doc.setProperties(documentProperties);

    // Report specific margins,
    // different for visual and tabular reports
    X_MARGIN = 10;
    Y_MARGIN = 13;
    BASE = 6;
    CONTENT_WIDTH = FULL_WIDTH - X_MARGIN * 2;
    CONTENT_HEIGHT = FULL_HEIGHT - Y_MARGIN * 2;

    // Load fonts
    await loadFonts();

    await loadImages();

    // Positioner
    let currentY = Y_MARGIN;

    setCurrentDate();

    // Create map images
    const layers = await getLibraryJson();
    let map = await createPdfMap(
        point,
        baseImages.mapPinOpaque,
        profile,
        selection,
        layers,
        geojson,
        pointsBySurveyByUda,
    );
    let trafficMap: string | undefined,
        housingMap: string | undefined,
        ssMap: string | undefined,
        marketSummaryMap: string | undefined;
    let scaleBarValue: string | undefined, lineLength: number | undefined;
    if (map) {
        const scaleBar = document.getElementsByClassName(
            'mapboxgl-ctrl-scale',
        )[0];
        const scaleBarStyle = getComputedStyle(scaleBar);
        const scaleBarWidth = parseInt(
            scaleBarStyle['width'].replace('px', ''),
        );
        scaleBarValue = scaleBar.innerHTML.replace('&nbsp;', ' ');
        lineLength = pxTomm(scaleBarWidth);
        map = await toggleLayerVisibilityBySource(map, 'aadt_us_2022', true);

        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units',
            false,
        );
        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units_count',
            false,
        );
        marketSummaryMap = map.getCanvas().toDataURL();
        map = await toggleLayerVisibilityBySource(map, 'housing_starts', true);
        ssMap = map.getCanvas().toDataURL();
        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units',
            true,
        );
        map = await toggleLayerVisibilityBySource(map, 'housing_starts', false);
        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units_count',
            true,
        );
        housingMap = map.getCanvas().toDataURL();
        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units',
            true,
        );
        map = await toggleLayerVisibilityBySource(map, 'housing_starts', true);
        map = await toggleLayerVisibilityBySource(
            map,
            'self_storage_units_count',
            true,
        );

        map = await toggleLayerVisibilityBySource(map, 'aadt_us_2022', false);

        for (let i = 0; i < selection.length; i += 1) {
            map = await toggleLayerVisibilityBySource(
                map,
                `radius_${selection[i]}`,
                true,
            );
        }

        trafficMap = map.getCanvas().toDataURL();

        // Destroy map
        const container = map.getContainer();
        map.remove();
        container.remove();
    }

    await renderFirstPage({
        reportTitle,
        address,
        selection,
        profile,
    });
    doc.addPage();

    renderSelfStorageCopyrightPage();

    let COLUMN_WIDTH = CONTENT_WIDTH;
    let currentPageNumber = 1;

    // Draw market summary map
    if (marketSummaryMap && lineLength && scaleBarValue) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;
        await drawMarketSummaryMap(
            currentY,
            marketSummaryMap,
            'EXECUTIVE SUMMARY',
            address,
            selection,
            profile,
            CONTENT_WIDTH,
            CONTENT_WIDTH,
        );
        // Draw map legend
        let legendItems = [
            'Facilities without pricing data',
            'Facilities with pricing data',
            'Construction sites',
        ];
        let legendColors: string[] | number[][] = [
            '#D2696A',
            '#619D7A',
            '#F1B82B',
        ];
        let legendRadii = [1.5, 1.5, 1.5];
        doc.setLineWidth(0);
        drawMaplegend(
            legendItems,
            legendColors,
            legendRadii,
            CONTENT_HEIGHT - 7,
        );
        drawMapScaleBar(lineLength, scaleBarValue, CONTENT_HEIGHT - 7);
        doc.setLineWidth(0);

        const legendItems2 = [
            'Large (1000+ Housing Starts)',
            'Medium (500-1000 Housing Starts)',
            'Small (< 500 Housing Starts)',
        ];
        const legendColors2 = [
            [0.0, 0.16, 0.4, 0.16],
            [0.0, 0.16, 0.4, 0.16],
            [0.0, 0.16, 0.4, 0.16],
        ];
        const legendRadii2 = [2.5, 2, 1.5];
        drawMaplegend(
            legendItems2,
            legendColors2,
            legendRadii2,
            CONTENT_HEIGHT + 1,
        );
    }
    doc.addPage();
    currentPageNumber++;
    currentY =
        initializePage(
            address,
            currentPageNumber,
            profile,
            reportTitle,
            selection,
            COLUMN_WIDTH,
            downloadPdfWithPagination,
            false,
            false,
        ) - 20;
    setTypography(TINY_TEXT_LIGHT);
    doc.text('EXECUTIVE SUMMARY', X_MARGIN, currentY);
    currentY += doc.getTextDimensions('EXECUTIVE SUMMARY').h + 6;
    drawSectionHeaders('OVERVIEW', '', X_MARGIN, currentY);
    currentY += 6;
    const headerTexts2: string[] = [];
    const unit2 = profile === 'radius' ? 'mile' : 'minute';
    selection.forEach((selection) => {
        const singular = selection === 1 ? '' : 's';
        headerTexts2.push(`${selection} ${unit2}${singular} ${profile}`);
    });
    const tableXCoords2: number[] = [];
    const SPACE_FOR_TEXT2 = Math.round(CONTENT_WIDTH * 0.66);
    const WIDTH_PER_COLUMN2 = SPACE_FOR_TEXT2 / selection.length;
    const START_OF_TEXT3 = CONTENT_WIDTH - SPACE_FOR_TEXT2;
    setTypography(SMALL_TEXT);
    selection.forEach((s, idx) => {
        tableXCoords2.push(START_OF_TEXT3 + WIDTH_PER_COLUMN2 * (idx + 1));
    });
    currentY = drawMarketSummaryTableHeader(
        currentY,
        headerTexts2,
        tableXCoords2,
    );
    currentY += 5;
    setTypography(TINY_TEXT_DARK);
    doc.text('Number of Self Storage Facilities', X_MARGIN + 2, currentY);
    summaryData.forEach((summary: SiteAnalysis, idx) => {
        setTypography(TINY_TEXT_70);
        doc.text(
            (summary.data as SiteAnalysisExecutiveSummary).facility_operating.toString(),
            tableXCoords2[idx] + 2.75,
            currentY,
            { align: 'right' },
        );
    });

    drawSeparator(currentY + 3, X_MARGIN, CONTENT_WIDTH);
    currentY += 8;

    setTypography(TINY_TEXT_DARK);
    doc.text('Number of Housing Starts', X_MARGIN + 2, currentY);
    executiveSummaryDetailed.forEach((section: SiteAnalysis, idx) => {
        setTypography(TINY_TEXT_70);
        doc.text(
            (section.data as SiteAnalysisExecutiveSummaryDetailed).housing_starts.length.toString(),
            tableXCoords2[idx] + 2.75,
            currentY,
            { align: 'right' },
        );
    });
    drawSeparator(currentY + 3, X_MARGIN, CONTENT_WIDTH);
    if (summaryData) {
        PDF_REPORT[0].forEach((viz) => {
            const viz2 = viz as SectionVisualizationLabel;

            // Loop through variables of table
            viz2.labels.forEach((label) => {
                currentY += 8;
                setTypography(TINY_TEXT_DARK);
                doc.text(label.title, X_MARGIN + 2, currentY);
                summaryData.forEach((summary, idx) => {
                    setTypography(TINY_TEXT_70);
                    const variable = (summary.data as SiteAnalysisBase)[
                        label.variable
                    ];

                    if (variable != null) {
                        const formattedVariable =
                            formatValue(
                                variable,
                                label.valueFormat ?? ValueFormat.FORMAT_NUMBER,
                            ) ?? 'N/A';
                        doc.text(
                            formattedVariable,
                            tableXCoords2[idx] + 2.75,
                            currentY,
                            { align: 'right' },
                        );
                    } else {
                        doc.text('N/A', tableXCoords2[idx] + 2.75, currentY, {
                            align: 'right',
                        });
                    }
                });
                drawSeparator(currentY + 3, X_MARGIN, CONTENT_WIDTH);
            });
            currentY += 6;
        });
    }

    if (executiveSummaryDetailed) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;

        const headerTexts1: string[] = [];
        const unit1 = profile === 'radius' ? 'mile' : 'minute';
        selection.forEach((selection) => {
            const singular = selection === 1 ? '' : 's';
            headerTexts1.push(`${selection} ${unit1}${singular} ${profile}`);
        });
        const tableXCoords1: number[] = [];
        const SPACE_FOR_TEXT1 = Math.round(CONTENT_WIDTH * 0.66);
        const WIDTH_PER_COLUMN1 = SPACE_FOR_TEXT1 / selection.length;
        const START_OF_TEXT2 = CONTENT_WIDTH - SPACE_FOR_TEXT1;
        selection.forEach((s, idx) => {
            tableXCoords1.push(START_OF_TEXT2 + WIDTH_PER_COLUMN1 * (idx + 1));
        });

        currentY = drawMarketDemographicTableHeader(
            currentY,
            headerTexts1,
            tableXCoords1,
        );

        PDF_REPORT[1].forEach((viz) => {
            const viz2 = viz as SectionVisualizationLabel;
            doc.setFillColor(...GRAY10);
            doc.rect(X_MARGIN, currentY, CONTENT_WIDTH, 10, 'F');
            setTypography(VALUE_STRONG);
            currentY += 6;
            doc.text(viz.header, X_MARGIN + 2, currentY);
            currentY += 2;
            // Loop through variables of table
            viz2.labels.forEach((label, idx) => {
                currentY += 7;
                if (idx !== 0) {
                    currentY += 1;
                }
                if (currentY > CONTENT_HEIGHT) {
                    doc.addPage();
                    currentPageNumber++;
                    currentY =
                        initializePage(
                            address,
                            currentPageNumber,
                            profile,
                            reportTitle,
                            selection,
                            COLUMN_WIDTH,
                            downloadPdfWithPagination,
                            false,
                            false,
                        ) - 20;
                    currentY = 20;
                    currentY = drawDemographicTableHeader(
                        currentY,
                        headerTexts1,
                        tableXCoords1,
                    );
                    currentY += 4;
                }
                setTypography(TINY_TEXT_DARK);
                doc.text(label.title, X_MARGIN + 2, currentY);
                // Now draw values for the variable
                executiveSummaryDetailed.forEach((section, idx) => {
                    const demographyData = (section.data as SiteAnalysisExecutiveSummaryDetailed)
                        .demography;
                    const variable = demographyData[label.variable];
                    if (variable != null) {
                        let percentageTextWidth = 0;
                        const formattedVariable =
                            formatValue(
                                variable,
                                label.valueFormat ?? ValueFormat.FORMAT_NUMBER,
                            ) ?? 'N/A';
                        // Calculate percentage difference between two columns
                        if (label.calculationColumn) {
                            const calcValue =
                                demographyData[label.calculationColumn];
                            const calcColumn =
                                ((variable - calcValue) / Math.abs(variable)) *
                                100;
                            const formattedCalculatedColumn = formatValue(
                                calcColumn,
                                viz2.valueFormat ?? ValueFormat.FORMAT_NUMBER,
                            );
                            setTypography(MICRO_TEXT_BOLD_LIGHT);
                            const percentageText = `(${
                                variable > calcValue ? `+` : ``
                            }${formattedCalculatedColumn}%)`;
                            doc.text(
                                percentageText,
                                tableXCoords1[idx] + 2.75,
                                currentY,
                                { align: 'right' },
                            );
                            percentageTextWidth =
                                doc.getTextWidth(percentageText) + 1;
                        }
                        setTypography(TINY_TEXT_DARK);
                        doc.text(
                            formattedVariable,
                            tableXCoords1[idx] + 2.75 - percentageTextWidth,
                            currentY,
                            { align: 'right' },
                        );
                    } else {
                        doc.text('N/A', tableXCoords1[idx] + 2.75, currentY, {
                            align: 'right',
                        });
                    }
                });
                drawSeparator(currentY + 3, X_MARGIN, CONTENT_WIDTH);
            });
            currentY += 5;
        });
    }
    // TRAFFIC MAP
    if (trafficMap && lineLength && scaleBarValue) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;

        await drawMap(currentY, trafficMap, 'ANNUAL AVERAGE DAILY TRAFFIC');
        // Draw traffic map legend
        const trafficItems = [
            '<1K',
            '1K',
            '3K', // 1K - 3K
            '5K', // 3K - 5K
            '10K', // 5K - 10K
            '50K', // 10K - 50K
            '>100K', // 50K - 100K
        ];
        const trafficColors = [
            '#1a9850',
            '#91cf60', // 1K - 3K
            '#d9ef8b', // 3K - 5K
            '#ffffbf', // 5K - 10K
            '#fee08b', // 10K - 50K
            '#fc8d59', // 50K - 100K
            '#d73027',
        ];
        let startX = X_MARGIN + 8;
        let startXofSecondToLast = 0;
        const trafficLabelsXCoords: number[] = [];
        trafficColors.forEach((item, idx) => {
            doc.setDrawColor(item);
            doc.setLineWidth(2);
            doc.setLineCap(0);
            if (idx === trafficColors.length - 2) {
                startXofSecondToLast = startX;
                trafficLabelsXCoords.push(startX);
                return;
            }
            if (idx === 0 || idx === trafficColors.length - 1) {
                doc.setLineCap('round');
                trafficLabelsXCoords.push(startX + 3.5);
            } else {
                trafficLabelsXCoords.push(startX);
            }
            if (idx === trafficColors.length - 1) {
                startX += 9;
            }
            doc.line(
                startX,
                CONTENT_HEIGHT - 12,
                startX + 9,
                CONTENT_HEIGHT - 12,
            );
            startX += 8.9;
        });
        doc.setLineCap(0);
        doc.setDrawColor(trafficColors[trafficColors.length - 2]);
        doc.line(
            startXofSecondToLast,
            CONTENT_HEIGHT - 12,
            startXofSecondToLast + 9,
            CONTENT_HEIGHT - 12,
        );

        trafficItems.forEach((item, idx) => {
            const lastItemOffset = idx === trafficItems.length - 1 ? 10 : 0;
            doc.text(
                item,
                trafficLabelsXCoords[idx] -
                    doc.getTextWidth(item) / 2 +
                    lastItemOffset,
                CONTENT_HEIGHT - 8,
            );
        });
        doc.text(
            'Annual Average Daily Traffic (AADT) is defined as the average 24-hour traffic volume at a given location over a full 365 days/year.',
            X_MARGIN + 7,
            CONTENT_HEIGHT - 1,
        );
        drawMapScaleBar(lineLength, scaleBarValue, CONTENT_HEIGHT - 12);
    }

    // BEGIN PRICING TRENDS LOOP
    // Get x coords of sizes
    // Fill coordinates with initial one, then calculate and append others
    const sizesXCoordinate = [X_MARGIN + CONTENT_WIDTH / 3 + 3];
    const sizes = ['5x5', '5x10', '10x10', '10x15', '10x20', '10x30'];
    sizes.forEach((size, idx) => {
        if (idx === 0) return;
        sizesXCoordinate.push(
            sizesXCoordinate[idx - 1] + doc.getTextWidth(sizes[idx - 1]) + 4,
        );
    });

    // Initial x and y coordinates for displaying facility info cards
    let facilityX = X_MARGIN + 3;
    let facilityY = Y_MARGIN + BASE * 3 + BASE * 2 + 32;
    let separatorIdx = 5;
    if (executiveSummaryDetailed) {
        const selectedFacilities = (executiveSummaryDetailed.find(
            (selectionOlapData) =>
                selectionOlapData.selection === selection[selection.length - 1],
        )?.data as SiteAnalysisExecutiveSummaryDetailed).facilities;

        const selectedRentalComps = (executiveSummaryDetailed.find(
            (selectionOlapData) =>
                selectionOlapData.selection === selection[selection.length - 1],
        )?.data as SiteAnalysisExecutiveSummaryDetailed).rental_comps;
        const rateTrends: PricingTrends = {
            all: selectedFacilities
                ? mergeFacilitiesAndRentalCompsAll(selectedRentalComps.all)
                : [],
            individual: selectedFacilities
                ? mergeFacilitiesAndRentalCompsIndividual(
                      selectedFacilities,
                      selectedRentalComps.individual,
                  )
                : [],
            monthly: [],
        };

        if (rateTrends.individual.length > 0) {
            rateTrends.individual.forEach((point, idx) => {
                // PRICING TRENDS TABLE HEADER, to be drawn after every sixth point
                if (idx % 6 === 0) {
                    // Reset facility x and y
                    facilityX = X_MARGIN + 3;
                    facilityY = Y_MARGIN + BASE * 3 + BASE * 2 + 21;

                    // Switch to new page
                    doc.addPage();
                    currentPageNumber++;
                    currentY =
                        initializePage(
                            address,
                            currentPageNumber,
                            profile,
                            reportTitle,
                            selection,
                            COLUMN_WIDTH,
                            downloadPdfWithPagination,
                            false,
                            false,
                        ) - 10;

                    currentY = drawPricingTrendsTableHeader(
                        selection,
                        profile,
                        currentY,
                        idx,
                        rateTrends.individual.length,
                        sizes,
                        sizesXCoordinate,
                    );
                }
                // Draw facility info with numbers
                facilityY = drawFacilityInfo(
                    point,
                    facilityX,
                    facilityY,
                    sizesXCoordinate,
                    sizes,
                    idx === separatorIdx,
                    idx === rateTrends.individual.length - 1,
                );
                if (idx === separatorIdx) {
                    separatorIdx += 6;
                }
            });
            // Draw info for all facilities
            // go to new page if required
            // This is only true if the last individual facility
            // lands exactly on the end of the page (that is, if the
            // number of facilities is divisible by 6)
            if (rateTrends.individual.length % 6 === 0) {
                // Reset facility x and y
                facilityX = X_MARGIN + 3;
                facilityY = Y_MARGIN + BASE * 3 + BASE * 2 + 32;

                // Switch to new page
                doc.addPage();
                currentPageNumber++;
                currentY =
                    initializePage(
                        address,
                        currentPageNumber,
                        profile,
                        reportTitle,
                        selection,
                        COLUMN_WIDTH,
                        downloadPdfWithPagination,
                        false,
                        false,
                    ) - 10;
                currentY = drawPricingTrendsTableHeader(
                    selection,
                    profile,
                    currentY,
                    null,
                    rateTrends.individual.length,
                    sizes,
                    sizesXCoordinate,
                );
            }
            const allFacilities = rateTrends.all[0];
            facilityY = drawFacilityInfo(
                allFacilities,
                facilityX,
                facilityY,
                sizesXCoordinate,
                sizes,
                true,
                false,
            );
        } else {
            doc.addPage();
            currentPageNumber++;
            currentY =
                initializePage(
                    address,
                    currentPageNumber,
                    profile,
                    reportTitle,
                    selection,
                    COLUMN_WIDTH,
                    downloadPdfWithPagination,
                    false,
                    false,
                ) - 10;
            drawSectionHeaders(`Rate Trends`, '', X_MARGIN, currentY);
            setTypography(SMALL_TEXT);
            doc.text('EXECUTIVE SUMMARY', X_MARGIN, currentY - 10);
            doc.text(
                'No facilities with pricing information in selected area.',
                X_MARGIN,
                currentY + 10,
            );
        }
    }

    if (executiveSummaryDetailed) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;

        const headerTexts: string[] = [];
        const unit = profile === 'radius' ? 'mile' : 'minute';
        selection.forEach((selection) => {
            const singular = selection === 1 ? '' : 's';
            headerTexts.push(`${selection} ${unit}${singular} ${profile}`);
        });
        const tableXCoords: number[] = [];
        const SPACE_FOR_TEXT = Math.round(CONTENT_WIDTH * 0.66);
        const WIDTH_PER_COLUMN = SPACE_FOR_TEXT / selection.length;
        const START_OF_TEXT = CONTENT_WIDTH - SPACE_FOR_TEXT;
        selection.forEach((s, idx) => {
            tableXCoords.push(START_OF_TEXT + WIDTH_PER_COLUMN * (idx + 1));
        });

        currentY = drawDemographicTableHeader(
            currentY,
            headerTexts,
            tableXCoords,
        );

        // Begin loop for tables
        PDF_REPORT[2].forEach((viz) => {
            const viz2 = viz as SectionVisualizationLabel;
            doc.setFillColor(...GRAY10);
            doc.rect(X_MARGIN, currentY, CONTENT_WIDTH, 10, 'F');
            setTypography(VALUE_STRONG);
            currentY += 6;
            doc.text(viz.header, X_MARGIN + 2, currentY);
            currentY += 2;
            // Loop through variables of table
            let labelHeight: number = 0;
            viz2.labels.forEach((label, idx) => {
                currentY += 7;
                if (idx !== 0) {
                    currentY += 1;
                }
                if (labelHeight !== 0) {
                    currentY += labelHeight - 2.82;
                }
                if (currentY > CONTENT_HEIGHT) {
                    doc.addPage();
                    currentPageNumber++;
                    currentY =
                        initializePage(
                            address,
                            currentPageNumber,
                            profile,
                            reportTitle,
                            selection,
                            COLUMN_WIDTH,
                            downloadPdfWithPagination,
                            false,
                            false,
                        ) - 20;
                    currentY = 20;
                    currentY = drawDemographicTableHeader(
                        currentY,
                        headerTexts,
                        tableXCoords,
                    );
                    currentY += 4;
                }
                setTypography(TINY_TEXT);
                const labelTitle = doc.splitTextToSize(label.title, 70);
                labelHeight = doc.getTextDimensions(labelTitle).h;
                doc.text(labelTitle, X_MARGIN + 2, currentY);
                // Now draw values for the variable
                executiveSummaryDetailed.forEach((section, idx) => {
                    const demographyData = (section.data as SiteAnalysisExecutiveSummaryDetailed)
                        .demography;
                    const variable = demographyData[label.variable];
                    if (variable != null) {
                        const formattedVariable =
                            formatValue(
                                variable,
                                label.valueFormat ?? ValueFormat.FORMAT_NUMBER,
                            ) ?? 'N/A';
                        doc.text(
                            formattedVariable,
                            tableXCoords[idx] + 2.75,
                            currentY,
                            { align: 'right' },
                        );
                    } else {
                        doc.text('N/A', tableXCoords[idx] + 2.75, currentY, {
                            align: 'right',
                        });
                    }
                });
                drawSeparator(currentY + labelHeight, X_MARGIN, CONTENT_WIDTH);
            });
            currentY += 5;
        });
    }

    // Competition map
    if (ssMap && lineLength && scaleBarValue) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;
        await drawMap(
            currentY,
            ssMap,
            `Storage Facilities (${selection.join(' - ')} ${getProfileUnit(
                selection,
                profile,
                false,
            )} ${profile})`,
            'DETAILED REPORT',
        );
        // Draw map legend
        let legendItems = [
            'Self storage facility',
            'Self storage with pricing',
            'Self storage facility (under construction)',
        ];
        let legendColors: string[] | number[][] = [
            '#D2696A',
            '#619D7A',
            '#F1B82B',
        ];
        let legendRadii = [1.5, 1.5, 1.5];
        drawMaplegend(
            legendItems,
            legendColors,
            legendRadii,
            CONTENT_HEIGHT - 15,
        );
        drawMapScaleBar(lineLength, scaleBarValue, CONTENT_HEIGHT - 15);
    }

    // LIST OF 10 NEAREST FACILITIES

    const tenNearestFacilities = (executiveSummaryDetailed.find(
        (selectionOlapData) =>
            selectionOlapData.selection === selection[selection.length - 1],
    )?.data as SiteAnalysisExecutiveSummaryDetailed).facilities.slice(0, 10);
    tenNearestFacilities.forEach((point, idx) => {
        // PRICING TRENDS TABLE HEADER, to be drawn after every sixth point
        if (idx % 6 === 0) {
            // Reset facility x and y
            facilityX = X_MARGIN + 3;
            facilityY = Y_MARGIN + BASE * 3 + BASE * 2 + 24;

            // Switch to new page
            doc.addPage();
            currentPageNumber++;
            currentY = initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            );

            currentY = drawPricingTenNearestTableHeader(
                selection,
                profile,
                currentY,
                idx,
                tenNearestFacilities.length,
                sizes,
                sizesXCoordinate,
            );
        }
        // Draw facility info with numbers
        facilityY = drawFacilityContactInfo(
            point,
            facilityX,
            facilityY,
            sizesXCoordinate,
            sizes,
            idx === separatorIdx,
            idx === tenNearestFacilities.length - 1,
        );
        if (idx === separatorIdx) {
            separatorIdx += 6;
        }
    });

    // Housing starts map
    if (housingMap && scaleBarValue && lineLength) {
        doc.addPage();
        currentPageNumber++;
        currentY =
            initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            ) - 20;
        await drawMap(
            currentY,
            housingMap,
            `HOUSING STARTS (${selection.join(' - ')} ${getProfileUnit(
                selection,
                profile,
                false,
            )} ${profile})`,
            'DETAILED REPORT',
        );
        // Draw map legend
        const legendItems = [
            'Large - 1000+ Housing Starts',
            'Medium - 500-1000 Housing Starts',
            'Small - < 500 Housing Starts',
        ];
        const legendColors = [
            [0.0, 0.16, 0.4, 0.16],
            [0.0, 0.16, 0.4, 0.16],
            [0.0, 0.16, 0.4, 0.16],
        ];
        const legendRadii = [3.5, 2.5, 1.5];
        drawMaplegend(
            legendItems,
            legendColors,
            legendRadii,
            CONTENT_HEIGHT - 13,
        );
        drawMapScaleBar(lineLength, scaleBarValue, CONTENT_HEIGHT - 13);
    }

    // 10 NEAREST HOUSING STARTS LIST
    const tenNearestHousingStarts = (executiveSummaryDetailed.find(
        (selectionOlapData) =>
            selectionOlapData.selection === selection[selection.length - 1],
    )?.data as SiteAnalysisExecutiveSummaryDetailed).housing_starts.slice(
        0,
        10,
    );
    tenNearestHousingStarts.forEach((point, idx) => {
        // PRICING TRENDS TABLE HEADER, to be drawn after every sixth point
        if (idx % 6 === 0) {
            // Reset facility x and y
            facilityX = X_MARGIN + 3;
            facilityY = Y_MARGIN + BASE * 3 + BASE * 2 + 24;

            // Switch to new page
            doc.addPage();
            currentPageNumber++;
            currentY = initializePage(
                address,
                currentPageNumber,
                profile,
                reportTitle,
                selection,
                COLUMN_WIDTH,
                downloadPdfWithPagination,
                false,
                false,
            );

            currentY = drawHousingTenNearestTableHeader(
                selection,
                profile,
                currentY,
                idx,
                tenNearestHousingStarts.length,
                sizes,
                sizesXCoordinate,
            );
        }

        // Draw facility info with numbers
        facilityY = drawHousingInfo(
            point,
            facilityX,
            facilityY,
            sizesXCoordinate,
            sizes,
            idx === separatorIdx,
            idx === tenNearestFacilities.length - 1,
            selection,
        );
        if (idx === separatorIdx) {
            separatorIdx += 6;
        }
    });

    // TODO check if this option can work with custom title
    //window.open(doc.output('bloburi'));
    doc.save(`${reportTitle} - ${address} - ${currentDate.numeric}.pdf`);
}

const mergeFacilitiesAndRentalCompsIndividual = (
    facilities: SiteAnalysisFacility[],
    rentalComps: SiteAnalysisRentalCompsIndividual[],
): FacilityInfo[] => {
    return facilities
        .map((facility) => {
            const facilityRentalData = rentalComps.filter(
                (rentalData) => rentalData.facility_id === facility.facility_id,
            );
            const nonClimateControlled = facilityRentalData
                .filter((rentalData) => !rentalData.climate_control)
                .reduce(
                    (obj, item) =>
                        Object.assign(obj, {
                            [item.size_category!]: {
                                min: item.min_street_rate,
                                max: item.max_street_rate,
                                avg: item.avg_street_rate,
                            },
                        }),
                    {},
                );
            const climateControlled = facilityRentalData
                .filter((rentalData) => rentalData.climate_control)
                .reduce(
                    (obj, item) =>
                        Object.assign(obj, {
                            [item.size_category!]: {
                                min: item.min_street_rate,
                                max: item.max_street_rate,
                                avg: item.avg_street_rate,
                            },
                        }),
                    {},
                );

            return {
                metadata: {
                    facilityId: facility.facility_id,
                    name: facility.name,
                    address: facility.full_address,
                    sqfeet: facility.sqft,
                    distance: formatValue(
                        facility.distance_miles,
                        ValueFormat.FORMAT_NUMBER_MAX_2_DECIMAL,
                    ),
                    website: facility.website,
                    phone: facility.telephone,
                },
                facilityId: facility.facility_id,
                climateControlled: climateControlled,
                nonClimateControlled: nonClimateControlled,
            };
        })
        .filter(
            (facility) =>
                Object.keys(facility.nonClimateControlled).length > 0 ||
                Object.keys(facility.climateControlled).length > 0,
        );
};

const mergeFacilitiesAndRentalCompsAll = (
    rentalComps: SiteAnalysisRentalCompsIndividual[],
): FacilityInfo[] => {
    const nonClimateControlled: FacilityCommonFilter = rentalComps
        .filter((rentalData) => !rentalData.climate_control)
        .reduce(
            (obj, item) =>
                Object.assign(obj, {
                    [item.size_category!]: {
                        min: item.min_street_rate,
                        max: item.max_street_rate,
                        avg: item.avg_street_rate,
                    },
                }),
            {},
        );
    const climateControlled: FacilityCommonFilter = rentalComps
        .filter((rentalData) => rentalData.climate_control)
        .reduce(
            (obj, item) =>
                Object.assign(obj, {
                    [item.size_category!]: {
                        min: item.min_street_rate,
                        max: item.max_street_rate,
                        avg: item.avg_street_rate,
                    },
                }),
            {},
        );

    return [
        {
            facilityId: 'all',
            climateControlled: climateControlled,
            nonClimateControlled: nonClimateControlled,
        },
    ];
};

const drawMap = async (
    currentY: number,
    mapToPNG: string,
    mapTitle: string,
    subTitle: string = 'EXECUTIVE SUMMARY',
) => {
    setTypography(TINY_TEXT_LIGHT);
    doc.text(subTitle, X_MARGIN, currentY);
    currentY += doc.getTextDimensions(subTitle).h + 6;
    drawSectionHeaders(mapTitle, '', X_MARGIN, currentY);
    currentY += doc.getTextDimensions(mapTitle).h + 3;
    setTypography(SMALL_TEXT);
    drawMapImage(currentY, mapToPNG);
};

const drawMarketSummaryMap = async (
    currentY: number,
    mapToPNG: string,
    mapTitle: string,
    address: string,
    selection: number[],
    profile: Profile,
    mapWidth = CONTENT_WIDTH,
    mapHeight = CONTENT_WIDTH,
) => {
    setTypography(H3);
    doc.text(mapTitle, X_MARGIN, currentY);
    currentY += doc.getTextDimensions(mapTitle).h + 2;
    doc.addImage(
        baseImages.marketSummaryIcon,
        'PNG',
        X_MARGIN,
        currentY,
        15,
        15,
    );
    setTypography(ADDRESS);
    doc.text(address, X_MARGIN + 18, currentY + 6);
    setTypography(LABEL);
    const addressSubtitle = `${selection.join(' - ')} ${getProfileUnit(
        selection,
        profile,
        false,
    )} ${profile}`;
    doc.text(addressSubtitle, X_MARGIN + 18, currentY + 12);
    setTypography(SMALL_TEXT);
    drawMapImage(currentY + 18, mapToPNG, mapWidth, mapHeight);
};

const drawMapImage = async (
    currentY: number,
    mapToPNG: string,
    mapWidth = CONTENT_WIDTH,
    mapHeight = CONTENT_WIDTH,
) => {
    doc.addImage(
        mapToPNG,
        'PNG',
        X_MARGIN,
        currentY,
        mapWidth,
        mapHeight,
        '',
        'FAST',
    );
    // simulate rounded edges over image
    doc.setDrawColor('#FFFFFF');
    const lw = doc.getLineWidth();
    doc.setLineWidth(4);
    doc.roundedRect(X_MARGIN, currentY, mapWidth, mapHeight, 5, 5, 'S');
    doc.setLineWidth(lw);
};

// PRIVATE helper methods

const initializePage = (
    address: string,
    currentPageNumber: number,
    profile: Profile,
    reportTitle: string,
    selection: number[],
    COLUMN_WIDTH: number,
    downloadPdfWithPagination: boolean,
    drawSelectionheader?: boolean,
    drawAnalysisType: boolean = true,
) => {
    drawHeader({ address, image: baseImages.mapPin, selection, profile });
    drawFooter(
        {
            image: baseImages.tractiqLogoFull,
            imageRatio: tractiqLogoFullRatio,
        },
        '  |   Self Storage',
    );

    if (downloadPdfWithPagination) {
        drawPageNumber(currentPageNumber);
    }

    // Reset positioner to the beginning of the page
    let modY = Y_MARGIN + BASE * 3;

    if (drawAnalysisType) {
        // Page title
        setTypography(H2);
        const suffix =
            profile === 'radius'
                ? '(radius)'
                : `(${AnalysisProfile[profile].dingoProfile} time)`;
        doc.text(`${reportTitle.toUpperCase()} ${suffix}`, X_MARGIN, modY);
        modY += BASE * 2;
    }

    if (drawSelectionheader) {
        drawSelectionHeaders(
            selection,
            selection.length === 1,
            COLUMN_WIDTH,
            X_MARGIN,
            modY,
            profile,
        );
        modY += BASE;
    }

    return modY + BASE * 2;
};

const loadFonts = async () => {
    const barlowCondensedBold = await barlowCondensedBoldFont;
    doc.addFileToVFS('BarlowCondensed-Bold.ttf', barlowCondensedBold);
    doc.addFont('BarlowCondensed-Bold.ttf', 'BarlowCondensed', 'bold');
    const montserratRegular = await montserratRegularFont;
    doc.addFileToVFS('Montserrat-Regular.ttf', montserratRegular);
    doc.addFont('Montserrat-Regular.ttf', 'Montserrat', 'regular');
    const montserratBold = await montserratBoldFont;
    doc.addFileToVFS('Montserrat-Bold.ttf', montserratBold);
    doc.addFont('Montserrat-Bold.ttf', 'Montserrat', 'bold');
    const montserratSemiBold = await montserratSemiBoldFont;
    doc.addFileToVFS('Montserrat-SemiBold.ttf', montserratSemiBold);
    doc.addFont('Montserrat-SemiBold.ttf', 'Montserrat', 'semibold');
    const sourceSansProRegular = await sourceSansProRegularFont;
    doc.addFileToVFS('SourceSansPro-Regular.ttf', sourceSansProRegular);
    doc.addFont('SourceSansPro-Regular.ttf', 'SourceSansPro', 'regular');
    const sourceSansProItalic = await sourceSansProItalicFont;
    doc.addFileToVFS('SourceSansPro-Italic.ttf', sourceSansProItalic);
    doc.addFont('SourceSansPro-Italic.ttf', 'SourceSansPro', 'italic');
    const sourceSansProBold = await sourceSansProBoldFont;
    doc.addFileToVFS('SourceSansPro-Bold.ttf', sourceSansProBold);
    doc.addFont('SourceSansPro-Bold.ttf', 'SourceSansPro', 'bold');
    const sourceSansProSemiBold = await sourceSansProSemiBoldFont;
    doc.addFileToVFS('SourceSansPro-SemiBold.ttf', sourceSansProSemiBold);
    doc.addFont('SourceSansPro-SemiBold.ttf', 'SourceSansPro', 'semibold');
    // We load the same font again because svg2pdf does not define the regular font as
    // 'regular', but as 'normal'. This font is only needed for svg2pdf to correctly
    // detect font families in SVG
    doc.addFont('SourceSansPro-Regular.ttf', 'SourceSansPro', 'normal');
};

const loadImages = async () => {
    baseImages = {
        tractiqLogoFull: await tractiqLogoFullImage,
        tractiqLogoLight: await tractiqLogoLightImage,
        mapPin: await mapPinImage,
        marketSummaryIcon: await marketSummaryIconImage,
        cover: await cover,
        coverPin: await coverPin,
        websiteIcon: await websiteIcon,
        phoneIcon: await phoneIcon,
        addressIcon: await addressIcon,
        mapPinOpaque: await mapPinImageOpaque,
    };
};

const setCurrentDate = () => {
    const timestamp = new Date();
    currentDate = {
        long: timestamp.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }),
        medium: timestamp.toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }),
        year: timestamp.toLocaleDateString('en-us', { year: 'numeric' }),
        numeric: timestamp.toISOString().split('T')[0], // new Date().toISOString() - 2022-03-03T15:43:55.152Z
    };
};

const setTypography = (typographyClass: PdfReportTypographyClass) => {
    const { color, size, fontName, fontStyle } = typographyClass;
    doc.setTextColor(...color);
    doc.setFontSize(size);
    doc.setFont(fontName, fontStyle);
};

const drawSeparator = (
    y: number,
    x: number = 0,
    width: number = FULL_WIDTH,
    lineWidth: number = 0.1,
    lineColor: ColorRGB = GRAY20,
) => {
    doc.setDrawColor('#DEDEDE');
    doc.setLineWidth(lineWidth);
    doc.line(x, y, x + width, y);
};

const drawVerticalSeparator = (
    y: number,
    x: number = 0,
    width: number = FULL_WIDTH,
) => {
    doc.setDrawColor('#EBEBEB');
    doc.setLineWidth(0.1);
    doc.line(x, y, x, y + width);
};

const drawUpArrow = (x: number, y: number) => {
    doc.setDrawColor(...GREEN);
    doc.setLineWidth(0.2);
    doc.setLineCap('round');
    doc.line(x, y - 1.8, x, y - 0.2);

    doc.line(x - 0.5, y - 1.5, x, y - 2);
    doc.line(x + 0.5, y - 1.5, x, y - 2);
    doc.setLineCap(0);
};

const drawDownArrow = (x: number, y: number) => {
    doc.setDrawColor(...RED);
    doc.setLineWidth(0.2);
    doc.setLineCap('round');
    doc.line(x, y - 2, x, y - 0.4);

    doc.line(x - 0.5, y - 0.7, x, y - 0.2);
    doc.line(x + 0.5, y - 0.7, x, y - 0.2);
    doc.setLineCap(0);
};

const drawPricingTrendsTableHeader = (
    selection: number[],
    profile: Profile,
    currentY: number,
    idx: number | null,
    noOfPoints: number,
    sizes: string[],
    sizesXCoordinate: number[],
) => {
    drawSectionHeaders(`Rate Trends`, '', X_MARGIN, currentY);
    setTypography(TINY_TEXT_LIGHT);
    doc.text('EXECUTIVE SUMMARY', X_MARGIN, currentY - 10);

    // Draw the "a-b of c facilities" text
    // if idx is null, we are in a special case where
    // we have a page which only contains the metafacility
    // (annual prices)
    currentY += 10;
    if (idx !== null) {
        const facilityCountText = `${idx + 1}-${
            idx + 6 < noOfPoints ? idx + 6 : noOfPoints
        } of ${noOfPoints} facilities`;
        doc.text(
            facilityCountText,
            X_MARGIN + CONTENT_WIDTH - 3 - doc.getTextWidth(facilityCountText),
            currentY,
        );
    } else {
        doc.text(
            'Annual rent prices',
            X_MARGIN + CONTENT_WIDTH - doc.getTextWidth('Annual rent prices'),
            currentY,
        );
    }

    doc.text('Trailing 12 Months', X_MARGIN, currentY);
    let relX = X_MARGIN + doc.getTextWidth('Trailing 12 Months') + 4;
    drawVerticalSeparator(currentY - 3, relX, 4);
    relX += 4;
    drawUpArrow(relX, currentY);
    relX += 3;
    doc.text('High', relX, currentY);
    relX += doc.getTextWidth('High') + 4;
    drawDownArrow(relX, currentY);
    relX += 3;
    doc.text('Low', relX, currentY);
    currentY += 2;
    doc.setDrawColor(...GRAY20);
    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    currentY += 2;

    // Draw text for table header
    // Text for NonCC and CC is hardcoded but that is fine
    // since a change in that regard would mean changing the whole PDF anyway
    setTypography(TINY_TEXT_BOLD_LIGHT);
    doc.text('Self Storage Facility', X_MARGIN + 2, currentY + 6);
    setTypography(TINY_TEXT_BOLD_LIGHT);
    doc.text(
        'Climate Controlled (CC)',
        X_MARGIN + (CONTENT_WIDTH / 3) * 2 + 2.5,
        currentY + 3,
    );
    doc.text(
        'Non-Climate Controlled (NCC)',
        X_MARGIN + CONTENT_WIDTH / 3 + 2.5,
        currentY + 3,
    );
    currentY += 9;

    // Draw sizes using precalculated x coordinates
    for (let i = 0; i < 2; i += 1) {
        setTypography(MICRO_TEXT_BOLD_LIGHT);
        for (let j = 0; j < sizes.length; j += 1) {
            // For i=1 (CC), strings get shifted by 65 on the x axis
            doc.text(sizes[j], sizesXCoordinate[j] + i * 65, currentY);
        }
    }
    currentY += 2;
    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    return currentY;
};

const drawFacilityInfo = (
    point: FacilityInfo,
    x: number,
    y: number,
    sizesXCoordinate: number[],
    sizes: string[],
    isLastOnPage: boolean,
    isLastIndividualFacility: boolean,
) => {
    if (point.metadata == null) {
        point.metadata = {
            name: '',
            address: '',
            sqfeet: '',
            facilityId: point.facilityId,
            distance: '',
            website: '',
            phone: '',
        };
    }
    const startY = y;
    const drawingAnnualData = point.facilityId === 'all';
    if (drawingAnnualData) {
        doc.setFillColor('#F5F5F5');
        doc.rect(X_MARGIN, y - 6, CONTENT_WIDTH, 21, 'F');
        doc.setFillColor('#FFFFFF');
    }
    setTypography(MICRO_TEXT);
    doc.setFillColor('#F5F5F5');
    const addressText = doc.splitTextToSize(
        drawingAnnualData ? '12-Month Market Average' : point.metadata.address,
        CONTENT_WIDTH / 3 - 12,
    );
    setTypography(TINY_TEXT_BOLD_BLACK);
    const facilityTitleString = doc.splitTextToSize(
        point.metadata.name,
        CONTENT_WIDTH,
    );
    setTypography(MICRO_TEXT);

    if (!drawingAnnualData) {
        doc.setFillColor('#F5F5F5');
        doc.rect(
            X_MARGIN,
            y - 6,
            CONTENT_WIDTH,
            (doc.getTextDimensions(facilityTitleString).h + 1) * 2,
            'F',
        );
        setTypography(TINY_TEXT_BOLD_BLACK);
        y -= 1.35;
        doc.text(facilityTitleString, x, y);
        y += doc.getTextDimensions(facilityTitleString).h + 3;
    }

    setTypography(MICRO_TEXT);
    doc.text(addressText, x, y);
    if (!drawingAnnualData) {
        setTypography(MICRO_TEXT_LIGHT);
        doc.text('avg.', x + 56, y);
    }

    setTypography(MICRO_TEXT);
    drawFacilityUnitPrices(
        x,
        y,
        point,
        'avg',
        sizes,
        sizesXCoordinate,
        drawingAnnualData,
    );

    y += doc.getTextDimensions(addressText).h;
    y += 2.5;

    drawFacilityUnitPrices(
        x,
        y,
        point,
        'max',
        sizes,
        sizesXCoordinate,
        drawingAnnualData,
    );

    y += 5.5;
    drawFacilityUnitPrices(
        x,
        y,
        point,
        'min',
        sizes,
        sizesXCoordinate,
        drawingAnnualData,
    );

    y += 6;

    if (!drawingAnnualData && !isLastOnPage) {
        drawSeparator(
            y - 4,
            X_MARGIN,
            CONTENT_WIDTH,
            isLastIndividualFacility ? 0.3 : 0.2,
            isLastIndividualFacility ? GRAY50 : GRAY40,
        );
    }
    y += 3;
    doc.setDrawColor('#DEDEDE');
    doc.setLineWidth(0.1);
    if (drawingAnnualData) {
        doc.line(x + 55, startY - 4, x + 55, y - 6);
        doc.line(x + 61.5, startY - 4, x + 61.5, y - 6);
        doc.line(x + 128, startY - 4, x + 128, y - 6);
    } else {
        doc.line(x + 55, startY + 2.5, x + 55, y - 7);
        doc.line(x + 61.5, startY + 2.5, x + 61.5, y - 7);
        doc.line(x + 128, startY + 2.5, x + 128, y - 7);
    }

    return y;
};

const drawPricingTenNearestTableHeader = (
    selection: number[],
    profile: Profile,
    currentY: number,
    idx: number,
    noOfPoints: number,
    sizes: string[],
    sizesXCoordinate: number[],
) => {
    drawSectionHeaders(`Storage Facilities Report`, '', X_MARGIN, currentY);
    setTypography(TINY_TEXT_LIGHT);
    doc.text('DETAILED REPORT', X_MARGIN, currentY - 10);

    // Draw the "a-b of c facilities" text
    currentY += 10;
    const facilityCountText = `${idx + 1}-${
        idx + 6 < noOfPoints ? idx + 6 : noOfPoints
    } of ${noOfPoints} facilities`;
    doc.text(
        facilityCountText,
        X_MARGIN + CONTENT_WIDTH - 3 - doc.getTextWidth(facilityCountText),
        currentY,
    );

    currentY += 2;
    doc.setDrawColor(...GRAY20);
    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);

    // Draw text for table header
    setTypography(TINY_TEXT_BOLD_LIGHT);
    doc.text('10 Nearest Facilities by Distance', X_MARGIN + 2, currentY + 5);
    setTypography(TINY_TEXT_BOLD_LIGHT);
    doc.text('Contact Info', X_MARGIN + CONTENT_WIDTH / 3 + 10, currentY + 5);

    currentY += 8;

    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    return currentY;
};
const drawHousingTenNearestTableHeader = (
    selection: number[],
    profile: Profile,
    currentY: number,
    idx: number,
    noOfPoints: number,
    sizes: string[],
    sizesXCoordinate: number[],
) => {
    const tableXCoords = [94, 125, 167, 190];
    drawSectionHeaders(`HOUSING STARTS REPORT`, '', X_MARGIN, currentY);
    setTypography(TINY_TEXT_LIGHT);
    doc.text('DETAILED REPORT', X_MARGIN, currentY - 10);

    // Draw the "a-b of c facilities" text
    currentY += 10;
    const facilityCountText = `${idx + 1}-${
        idx + 6 < noOfPoints ? idx + 6 : noOfPoints
    } of ${noOfPoints} facilities`;
    doc.text(
        facilityCountText,
        X_MARGIN + CONTENT_WIDTH - 3 - doc.getTextWidth(facilityCountText),
        currentY,
    );

    currentY += 2;
    doc.setDrawColor(...GRAY20);
    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);

    // Draw text for table header
    setTypography(TINY_TEXT_BOLD_LIGHT);
    doc.text('10 Nearest Facilities by Distance', X_MARGIN + 2, currentY + 5);

    doc.text('Project stage', tableXCoords[0], currentY + 5);

    doc.text('Num. of Units', tableXCoords[1], currentY + 5);

    doc.text('Cost', tableXCoords[2], currentY + 5);

    doc.text('Start Date', tableXCoords[3], currentY + 5);

    currentY += 8;

    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    return currentY;
};

const drawFacilityContactInfo = (
    point: MapQueryResponse,
    x: number,
    y: number,
    sizesXCoordinate: number[],
    sizes: string[],
    isLastOnPage: boolean,
    isLastIndividualFacility: boolean,
) => {
    if (point == null) {
        point = {
            name: '',
            full_address: '',
            sqft: '',
            facility_id: 1,
            distance: '',
            website: '',
            telephone: '',
        };
    }
    y += 3.5;
    if (point.telephone === 'null' || point.telephone === '') {
        point.telephone = null;
    }
    // Draw name of facility and address
    setTypography(SMALL_TEXT_DARK_BOLD);
    const facilityName = doc.splitTextToSize(point.name, CONTENT_WIDTH / 3 + 6);
    doc.text(facilityName, X_MARGIN + 2, y);
    setTypography(SMALL_TEXT);
    doc.addImage(
        baseImages.addressIcon,
        'PNG',
        X_MARGIN + CONTENT_WIDTH / 3 + 10,
        y - 2.5,
        3,
        3,
    );
    setTypography(SMALL_TEXT_DARK);
    doc.text(point.full_address, X_MARGIN + CONTENT_WIDTH / 3 + 15, y);
    y += 6 + doc.getTextDimensions(facilityName).h;
    // Draw square footage and phone number
    setTypography(SMALL_TEXT_DARK);
    const facilitySqft = doc.splitTextToSize(
        `${point.sqft ? point.sqft.toLocaleString() : 'null'} square feet`,
        CONTENT_WIDTH / 3 + 8,
    );

    doc.text(facilitySqft, X_MARGIN + 2, y);
    setTypography(SMALL_TEXT);
    doc.addImage(
        baseImages.phoneIcon,
        'PNG',
        X_MARGIN + CONTENT_WIDTH / 3 + 10,
        y - 2.5,
        3,
        3,
    );
    if (!point.telephone) {
        setTypography(TINY_TEXT_ITALIC_LIGHT);
    }
    setTypography(SMALL_TEXT_DARK);
    doc.text(point.telephone ?? 'N/A', X_MARGIN + CONTENT_WIDTH / 3 + 15, y);
    y += 6 + doc.getTextDimensions(facilitySqft).h;

    // Draw distance and website
    setTypography(SMALL_TEXT_DARK);
    const facilityDistance = doc.splitTextToSize(
        `${formatValue(
            point.distance_miles,
            ValueFormat.FORMAT_NUMBER_MAX_2_DECIMAL,
        )} miles`,
        CONTENT_WIDTH / 3 + 8,
    );
    doc.text(facilityDistance, X_MARGIN + 2, y);
    setTypography(SMALL_TEXT);
    doc.addImage(
        baseImages.websiteIcon,
        'PNG',
        X_MARGIN + CONTENT_WIDTH / 3 + 10,
        y - 2.5,
        3,
        3,
    );
    try {
        const url = new URL(point.website).hostname;
        doc.setTextColor('#1257DD');
        doc.textWithLink(url, X_MARGIN + CONTENT_WIDTH / 3 + 15, y, {
            url: point.website,
        });
    } catch (error) {
        setTypography(TINY_TEXT_ITALIC_LIGHT);
        doc.text('N/A', X_MARGIN + CONTENT_WIDTH / 3 + 15, y);
    }
    y += 3.5;
    doc.line(X_MARGIN, y, X_MARGIN + CONTENT_WIDTH, y);
    y += 2;

    return y;
};

const drawHousingInfo = (
    housingInfo: SiteAnalysisFacility,
    x: number,
    y: number,
    sizesXCoordinate: number[],
    sizes: string[],
    isLastOnPage: boolean,
    isLastIndividualFacility: boolean,
    selection: number[],
) => {
    let tableXCoords = [94, 125, 167, 190];
    setTypography(SMALL_TEXT);
    const columnNames = [
        'Project Stage',
        'Num. of Units',
        'Cost',
        'Start Date',
    ];
    tableXCoords = tableXCoords.map(
        (coord, idx) => coord + doc.getTextWidth(columnNames[idx]) - 2,
    );
    const titleValue = housingInfo.name;
    const addressValue = housingInfo.address;
    const distanceValue = formatValue(
        housingInfo.distance_miles,
        ValueFormat.FORMAT_NUMBER_MAX_2_DECIMAL,
    );
    const projectStageValue = housingInfo.project_stage;
    const numOfUnitsValue = housingInfo.number_of_units;
    const costValue = housingInfo.cost;
    const startDateValue = housingInfo.start_date;
    const point = {
        title: titleValue,
        address: addressValue,
        distance: distanceValue,
        projectStage: projectStageValue,
        numOfUnits: numOfUnitsValue,
        cost: costValue,
        startDate: startDateValue,
    };

    y += 3.5;
    // Draw name of facility and address
    setTypography(SMALL_TEXT_DARK_BOLD);
    const facilityName = doc.splitTextToSize(
        point.title,
        CONTENT_WIDTH / 3 + 6,
    );
    doc.text(facilityName, X_MARGIN + 2, y);
    y += 2 + doc.getTextDimensions(facilityName).h;
    // Draw square footage and phone number
    setTypography(SMALL_TEXT_DARK);
    const facilityAddress = doc.splitTextToSize(
        point.address,
        CONTENT_WIDTH / 3 + 8,
    );
    doc.text(facilityAddress, X_MARGIN + 2, y);
    setTypography(TINY_TEXT);
    const facilityProjectStage = doc.splitTextToSize(
        point.projectStage,
        doc.getTextWidth('Project stage') + 5,
    );

    doc.text(facilityProjectStage ?? 'N/A', tableXCoords[0], y, {
        align: 'right',
    });
    if (!point.numOfUnits) {
        setTypography(TINY_TEXT_ITALIC_LIGHT);
        point.numOfUnits = 'N/A';
    } else {
        setTypography(TINY_TEXT);
        point.numOfUnits = `${point.numOfUnits} UNITS`;
    }
    doc.text(point.numOfUnits, tableXCoords[1], y, {
        align: 'right',
    });

    if (!point.cost) {
        setTypography(TINY_TEXT_ITALIC_LIGHT);
        point.cost = 'N/A';
    } else {
        setTypography(TINY_TEXT);
        point.cost = formatValue(point.cost, ValueFormat.FORMAT_CURRENCY);
    }
    doc.text(point.cost ?? 'N/A', tableXCoords[2] + 1.5, y, { align: 'right' });

    if (!point.startDate) {
        setTypography(TINY_TEXT_ITALIC_LIGHT);
        point.startDate = 'N/A';
    } else {
        setTypography(TINY_TEXT);
    }
    doc.text(point.startDate ?? 'N/A', tableXCoords[3] + 1, y, {
        align: 'right',
    });

    y += doc.getTextDimensions(facilityName).h + 2;
    // Draw distance and website
    setTypography(SMALL_TEXT_BLACK);
    const facilityDistance = doc.splitTextToSize(
        `${point.distance} miles`,
        CONTENT_WIDTH / 3 + 8,
    );
    doc.text(facilityDistance, X_MARGIN + 2, y);

    y += 3.5;

    doc.line(X_MARGIN, y, X_MARGIN + CONTENT_WIDTH, y);
    y += 2;
    return y;
};

const drawFacilityUnitPrices = (
    x: number,
    y: number,
    point: FacilityInfo,
    priceType: 'min' | 'max' | 'avg',
    sizes: string[],
    sizesXCoordinate: number[],
    drawingAnnualData: boolean,
) => {
    if (point.metadata == null) {
        point.metadata = {
            name: '',
            address: '',
            sqfeet: '',
            facilityId: point.facilityId,
            distance: '',
            website: '',
            phone: '',
        };
    }

    switch (priceType) {
        case 'min':
            if (drawingAnnualData) {
                doc.text(`12-Month Market Average Minimum`, x, y);
            } else {
                setTypography(MICRO_TEXT_LIGHT);
                drawDownArrow(x + 58.5, y);
                doc.text(`${point.metadata.distance ?? 'N/A'} miles`, x, y);
                setTypography(MICRO_TEXT);
            }
            break;
        case 'max':
            setTypography(MICRO_TEXT);
            if (drawingAnnualData) {
                doc.text(`12-Month Market Average Maximum`, x, y);
            } else {
                drawUpArrow(x + 58.5, y);
                doc.text(
                    `${
                        point.metadata.sqfeet
                            ? point.metadata.sqfeet.toLocaleString()
                            : 'N/A'
                    } sq. feet`,
                    x,
                    y,
                );
            }
            break;
        default:
            break;
    }

    // draw min/max/avg prices
    sizes.forEach((size, idx) => {
        // nonClimateControlled
        let num = point.nonClimateControlled[size]?.[priceType];
        if (num) {
            setTypography(MICRO_TEXT);
            num = `$${(Math.round(Number(num) * 100) / 100).toFixed(2)}`;
        } else {
            setTypography(MICRO_TEXT_LIGHT);
            num = 'N/A';
        }
        let xCoord =
            sizesXCoordinate[idx] +
            doc.getTextWidth(sizes[idx]) -
            doc.getTextWidth(num) +
            0.25;
        doc.text(num, xCoord, y);
        // CC
        num = point.climateControlled[size]?.[priceType];
        if (num) {
            setTypography(MICRO_TEXT);
            num = `$${(Math.round(Number(num) * 100) / 100).toFixed(2)}`;
        } else {
            setTypography(MICRO_TEXT_LIGHT);
            num = 'N/A';
        }

        xCoord =
            sizesXCoordinate[idx] +
            doc.getTextWidth(sizes[idx]) -
            doc.getTextWidth(num) +
            65.25;
        doc.text(num, xCoord, y);
    });
};

const drawHeader = ({ address, image, selection, profile }: RenderOptions) => {
    // Map marker
    doc.addImage(image, 'PNG', BASE * 1.5, Y_MARGIN * 0.38, 3, 3.75);

    // Address & date
    const locationAndDate = `${address}  |  ${
        selection && selection.join(' - ')
    } ${getProfileUnit(
        selection ?? [1, 3, 5],
        profile ?? 'radius',
        false,
    )} ${profile}`;
    const y = (Y_MARGIN * 3) / 5;
    setTypography(SMALL_TEXT);
    doc.text(locationAndDate, Y_MARGIN + 1, y, {
        align: 'left',
    });

    // Header, site analysis
    setTypography(H5);
    doc.text('SITE ANALYSIS', FULL_WIDTH - Y_MARGIN * 0.5, y, {
        align: 'right',
    });

    // Header line separator
    drawSeparator(Y_MARGIN);
};

const drawFooter = (
    { image, imageRatio = 0 }: RenderOptions,
    footerText: string,
) => {
    const y = FULL_HEIGHT - Y_MARGIN;
    const imageHeight = (Y_MARGIN * 2) / 5;
    doc.addImage(
        image,
        'PNG',
        Y_MARGIN / 2,
        y + imageHeight - 1.5,
        imageHeight * imageRatio,
        imageHeight,
    );
    setTypography(SELECTION);
    doc.text(
        footerText,
        Y_MARGIN / 2 + imageHeight * imageRatio + 1.5,
        y + 2 * imageHeight - 3,
    );
    // Footer line separator
    drawSeparator(y);
};

const drawPageNumber = (pageNumber: number) => {
    // Page number
    const y = FULL_HEIGHT - Y_MARGIN;
    setTypography(LIST_ITEM);
    doc.text(
        `${pageNumber}`,
        FULL_WIDTH - Y_MARGIN * 0.5,
        y + Y_MARGIN * 0.5 + 1,
        {
            align: 'center',
        },
    );
};

const renderFirstPage = ({
    reportTitle = 'New Report',
    address = '',
    selection,
    profile,
}: RenderOptions) => {
    // First page has fixed margins and visual layout in all reports
    let x = 10;
    let y = 13;

    // First page background
    /*
    doc.setFillColor(...TRACTIQ_PRIMARY_DARK);
    doc.rect(0, 0, FULL_WIDTH, FULL_HEIGHT, 'F');*/
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    doc.addImage(baseImages.cover, 'JPG', 0, 0, width, height);

    const tractiqLogoLight = baseImages.tractiqLogoLight;
    const tractiqLogoLightRatio = 391 / 81; // Width to height ratio based on pixel dimensions of this particular image

    // Add logo
    const logoWidth = CONTENT_WIDTH / 7; // Visual approximation

    const headerSubtitle = '|  Self Storage';
    const subtitleWidth = doc.getTextWidth(headerSubtitle);
    doc.addImage(
        tractiqLogoLight,
        'PNG',
        x + (CONTENT_WIDTH - logoWidth - subtitleWidth) * 0.5,
        y,
        logoWidth,
        logoWidth / tractiqLogoLightRatio,
    );

    setTypography(SUBTITLE);
    doc.text(
        headerSubtitle,
        x + (CONTENT_WIDTH + logoWidth - subtitleWidth) * 0.5 + 2,
        y + logoWidth / tractiqLogoLightRatio / 2 + 1.5,
    );

    // First page title
    x = 10 * 2;
    y = (13 + CONTENT_HEIGHT) * 0.5;
    if (reportTitle.length > 25) {
        setTypography(H1_SMALLER);
    } else {
        setTypography(H1);
    }
    doc.text(reportTitle, x, y);

    // Map marker
    y += 13 / 2;
    doc.addImage(baseImages.coverPin, 'PNG', x, y + 2, 4.875, 6);
    // Subtitle
    setTypography(SUBTITLE);
    // parse address or title
    let subtitle = address;
    const subSplit = subtitle.split(',');
    if (subSplit[subSplit.length - 1] === ' United States') {
        subtitle = subSplit.slice(0, -1).join(',');
    } else {
        subtitle = subSplit.join(',');
    }
    let addressSubtitle = '';
    if (selection && profile) {
        addressSubtitle = `  |  ${selection.join(' - ')} ${getProfileUnit(
            selection,
            profile,
            false,
        )} ${profile}`;
    }

    const subtitleText = `${subtitle}${addressSubtitle}`;

    const subtitleLines = doc
        .setLineHeightFactor(1.5)
        .splitTextToSize(subtitleText, CONTENT_WIDTH - 10 * 5);
    doc.text(subtitleLines, x + 8, y + 13 * 0.5);

    // Footer
    y = 13 + CONTENT_HEIGHT;

    doc.text('TractIQ | Self Storage, Social Explorer Inc.', X_MARGIN, y);
    doc.text(currentDate.long, X_MARGIN + CONTENT_WIDTH, y, {
        align: 'right',
    });
};

const renderSelfStorageCopyrightPage = () => {
    // Copyrights
    setTypography(LABEL);
    doc.text(
        `This paper is a product of TractIQ | Self Storage\n` +
            'Social Explorer Inc.' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            `TractIQ | Self Storage\n` +
            'Subsidiary of Social Explorer Inc.\n' +
            `support@tractiq.com\n` +
            '\n' +
            '\n' +
            '\n' +
            `Copyright © ${currentDate.year} TractIQ | Self Storage\n` +
            '\n' +
            '\n' +
            '\n' +
            'All rights reserved. No part of this publication may be\n' +
            'uploaded or posted online without the prior written\n' +
            'permission of the authors. ',
        20,
        CONTENT_HEIGHT * 0.5,
    );
    // Footer
    doc.text(`Prepared on ${currentDate.long}`, 20, CONTENT_HEIGHT);
};

const drawSelectionHeaders = (
    selection: number[],
    isSingleSelection: boolean,
    COLUMN_WIDTH: number,
    currentX: number,
    currentY: number,
    profile: Profile,
) => {
    for (
        let selectionIdx = 0;
        selectionIdx < selection.length;
        selectionIdx++
    ) {
        const columnWidth = isSingleSelection ? CONTENT_WIDTH : COLUMN_WIDTH;
        const columnStart = currentX + (BASE + columnWidth) * selectionIdx;
        const columnCenter = columnStart + columnWidth * 0.5;

        // Draw gray rectangle
        doc.setFillColor(...GRAY10);
        doc.rect(columnStart, currentY, columnWidth, BASE, 'F');

        // Selection/drive time label
        setTypography(SELECTION);
        doc.text(
            `${selection[selectionIdx]} ${getProfileUnit(
                [selection[selectionIdx]],
                profile,
            )}`,
            columnCenter,
            currentY + BASE * 0.7,
            {
                align: 'center',
            },
        );
    }
};

const drawSectionHeaders = (
    header: string,
    title: string,
    currentX: number,
    currentY: number,
) => {
    let modY = 0;
    if (header) {
        setTypography(H4);
        doc.text(header.toUpperCase(), currentX, currentY, {
            align: 'left',
        });
        modY += BASE * 1.5;
    }

    if (title) {
        setTypography(H4);
        doc.text(title, currentX, currentY + modY, {
            align: 'left',
        });
        modY += BASE * 1.5;
    }

    return modY;
};

const drawMaplegend = (
    legendItems: string[],
    legendColors: string[] | number[][],
    legendRadii: number[],
    startY: number,
) => {
    let startX = X_MARGIN + 5;
    doc.setLineCap(0);
    doc.setDrawColor('#ffffff');
    doc.setLineWidth(0);
    setTypography(SMALL_TEXT_DARK);
    legendItems.forEach((item, idx) => {
        const colors = legendColors[idx];
        if (Array.isArray(colors)) {
            doc.setFillColor(colors[0], colors[1], colors[2], colors[3]);
        } else {
            doc.setFillColor(colors);
        }
        doc.circle(startX, startY, legendRadii[idx], 'FD');
        startX += legendRadii[idx] + 1;
        doc.text(item, startX, startY + 1);
        startX += doc.getTextWidth(item) + 8;
    });
};

const drawDemographicTableHeader = (
    currentY: number,
    headerTexts: string[],
    tableXCoords: number[],
) => {
    const RING_COLORS = ['#ED6A5E', '#F6BD4F', '#29CBC5'];
    setTypography(H5);
    doc.text('DETAILED REPORT', X_MARGIN, currentY);
    currentY += doc.getTextDimensions('DETAILED REPORT').h + 6;
    drawSectionHeaders('DEMOGRAPHICS', '', X_MARGIN, currentY);
    currentY += 6;
    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    currentY += 6;
    doc.line(X_MARGIN, currentY + 4, X_MARGIN + CONTENT_WIDTH, currentY + 4);

    setTypography(SMALL_TEXT_70_SB);
    headerTexts.forEach((text, idx) => {
        doc.setFillColor(RING_COLORS[idx]);
        doc.circle(
            tableXCoords[idx] - doc.getTextWidth(text),
            currentY - 1,
            1,
            'F',
        );
        doc.text(
            text,
            tableXCoords[idx] + 3 - doc.getTextWidth(text),
            currentY,
        );
    });
    currentY += 6;
    return currentY;
};

const drawMarketDemographicTableHeader = (
    currentY: number,
    headerTexts: string[],
    tableXCoords: number[],
) => {
    const RING_COLORS = ['#ED6A5E', '#F6BD4F', '#29CBC5'];

    setTypography(H5);
    doc.text('EXECUTIVE SUMMARY', X_MARGIN, currentY);
    currentY += doc.getTextDimensions('EXECUTIVE SUMMARY').h + 6;
    drawSectionHeaders('DEMOGRAPHICS', '', X_MARGIN, currentY);
    currentY += 6;

    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    currentY += 6;
    doc.line(X_MARGIN, currentY + 4, X_MARGIN + CONTENT_WIDTH, currentY + 4);

    setTypography(SMALL_TEXT_70_SB);
    headerTexts.forEach((text, idx) => {
        doc.setFillColor(RING_COLORS[idx]);
        doc.circle(
            tableXCoords[idx] - doc.getTextWidth(text),
            currentY - 1,
            1,
            'F',
        );
        doc.text(
            text,
            tableXCoords[idx] + 3 - doc.getTextWidth(text),
            currentY,
        );
    });
    currentY += 6;
    return currentY;
};

const drawMarketSummaryTableHeader = (
    currentY: number,
    headerTexts: string[],
    tableXCoords: number[],
) => {
    const RING_COLORS = ['#ED6A5E', '#F6BD4F', '#29CBC5'];
    const lineWidth: number = 0.1;
    const lineColor: ColorRGB = GRAY20;
    doc.setDrawColor(...lineColor);
    doc.setLineWidth(lineWidth);

    doc.line(X_MARGIN, currentY, X_MARGIN + CONTENT_WIDTH, currentY);
    currentY += 6;
    doc.line(X_MARGIN, currentY + 4, X_MARGIN + CONTENT_WIDTH, currentY + 4);

    setTypography(SMALL_TEXT_70_SB);
    headerTexts.forEach((text, idx) => {
        doc.setFillColor(RING_COLORS[idx]);
        doc.circle(
            tableXCoords[idx] - doc.getTextWidth(text),
            currentY - 1,
            1,
            'F',
        );
        doc.text(
            text,
            tableXCoords[idx] + 3 - doc.getTextWidth(text),
            currentY,
        );
    });
    currentY += 6;
    return currentY;
};

const pxTomm = (px: number) => {
    const element = document.createElement('div');
    document.body.appendChild(element);
    element.setAttribute('id', 'scaleBar');
    element.style.height = '100mm';
    element.style.display = 'none';
    const elStyle = getComputedStyle(element);
    const elHeight = parseInt(elStyle['height']);
    const lineLength = Math.floor(px / (elHeight / 100));
    element.remove();
    return lineLength;
};

const drawMapScaleBar = (
    lineLength: number,
    scaleBarValue: string,
    baseY: number,
) => {
    doc.setLineCap(0);
    doc.setDrawColor('#000000');
    doc.setLineWidth(0.5);

    doc.line(
        X_MARGIN +
            CONTENT_WIDTH -
            lineLength -
            doc.getTextWidth(scaleBarValue) -
            1,
        baseY + 1.25,
        X_MARGIN +
            CONTENT_WIDTH -
            lineLength -
            doc.getTextWidth(scaleBarValue) -
            1,
        baseY - 0.5,
    );
    doc.line(
        X_MARGIN +
            CONTENT_WIDTH -
            lineLength -
            doc.getTextWidth(scaleBarValue) -
            1,
        baseY + 1,
        X_MARGIN + CONTENT_WIDTH - doc.getTextWidth(scaleBarValue) - 1,
        baseY + 1,
    );
    doc.line(
        X_MARGIN + CONTENT_WIDTH - doc.getTextWidth(scaleBarValue) - 1,
        baseY + 1.25,
        X_MARGIN + CONTENT_WIDTH - doc.getTextWidth(scaleBarValue) - 1,
        baseY - 0.5,
    );
    doc.text(
        scaleBarValue,
        X_MARGIN + CONTENT_WIDTH - doc.getTextWidth(scaleBarValue),
        baseY + 1,
    );
};
