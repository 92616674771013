import React, { useEffect } from 'react';
import { Popover } from 'bootstrap';
import getPopOverContent from '../helpers/getDataInfoPopoverContent';

type Props = {
    showIcon?: boolean;
    visualizationIds: string[];
};

const ChartInfo = ({ showIcon = false, visualizationIds }: Props) => {

    useEffect(() => {
        const popoverTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="popover"]'),
        );

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl);
        });
    }, []);

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                tabIndex={0}
                className="btn m-0 p-0 justify-content-center"
                role="button"
                data-bs-toggle="popover"
                data-bs-trigger="focus"
                data-bs-content={getPopOverContent(visualizationIds)}
                data-bs-html="true"
            >
                {showIcon ?
                    <i className="material-icons-outlined"
                       style={{
                           paddingLeft: '5px',
                           display: 'flex',
                           fontSize: '18px',
                           color: 'rgba(75, 85, 99, 1)'}}> help_outline </i>
                    : <h6 className="color-black opacity-75 fw-normal text-start link--tractiq text-decoration-underline">
                        About this data </h6>
                }
            </a>
        </>
    );
};

export default ChartInfo;
