import {
    ChartExportInfo,
    Point,
    Profile,
    SectionVisualizationChart,
    SelfStorage,
    VisualizationExportLegend,
} from '../types';
import CHART_DATA_INFO from '../enums/DataDescriptions';
import { getChartAsSvgString } from './exportCharts';

const MAX_NO_OF_COLUMNS = 12;

/**
 * Returns adequate column class number for given selection
 * @param isSmallScreen Boolean - true if we are on small screens
 * @param noOfRings Number of rings in selection (1, 2, or 3)
 *
 * @remarks Bootstrap has a maximum column value of 12. If we want to let
 *          Bootstrap know that we will surely use only 2 columns,
 *          we need the col-6 class (6+6 = 12 --> two columns). For three
 *          columns, we need col-4 (4+4+4 = 12). This function returns the
 *          correct number X for the col-X class. For one selection/ring,
 *          that will be 12, for two 6, and for three 4. If we
 *          are on small screens, we only show one selection, so we return 12
 */
export const getColClassNumber = (
    isSmallScreen: boolean,
    noOfRings: number,
) => {
    const colNumber = isSmallScreen
        ? MAX_NO_OF_COLUMNS
        : MAX_NO_OF_COLUMNS / noOfRings;

    return colNumber;
};

/**
 *
 * @param chartOptions Options of chart from which we extract labels and colormap
 * @returns Object which contains labels and colors that need to be used when drawing the
 * legend
 */
const getLegendLabelsAndColors = (
    chartOptions: SectionVisualizationChart,
): VisualizationExportLegend => {
    let labels: string[] = [];
    let colors: string[] = [];
    if (chartOptions.chartType === 'pie') {
        labels = chartOptions.series[0].data.map((d) => d.category);
        if (
            chartOptions.exportOptions &&
            chartOptions.exportOptions.plotOptions &&
            chartOptions.exportOptions.plotOptions.pie
        ) {
            colors = chartOptions.exportOptions.plotOptions.pie
                .colors as string[];
        } else {
            // Fall back to black if no colors are available
            colors = labels.map((_) => '#000000');
        }

        for (let i = 0; i < labels.length; i += 1) {
            labels[i] = `${labels[i]}`;
        }
    } else if (chartOptions.isStacked) {
        colors = chartOptions.series.map((d) => d.color);
        labels = chartOptions.series.map((d) => d.name);
    }

    return { labels: labels, colors: colors };
};

/**
 * For given selection rings and visualization prepare ChartExportInfo
 *
 * @param visualization
 * @param selection
 */
export const getVisualizationChartExportInfo = (
    visualization: SectionVisualizationChart,
    selection: number[],
): ChartExportInfo[] => {
    const chartExportInfoRow: ChartExportInfo[] = [];
    // for each selection(ring) prepare the necessary info for chart export
    selection.forEach((ring) => {
        const chartExportInfo: ChartExportInfo = {
            id: visualization.id,
            title:
                visualization.header === ''
                    ? visualization.title
                    : visualization.header,
            type: visualization.type,
            rawSvg: getChartAsSvgString(
                `${visualization.id}${ring}`,
                visualization.exportOptions == null
                    ? {}
                    : visualization.exportOptions,
            ),
            chartWidth:
                visualization.exportOptions == null
                    ? undefined
                    : visualization.exportOptions.chart?.width,
            chartHeight:
                visualization.exportOptions == null
                    ? null
                    : visualization.exportOptions.chart?.height,
            legend:
                visualization.chartType === 'pie' || visualization.isStacked
                    ? getLegendLabelsAndColors(visualization)
                    : undefined,
            description: CHART_DATA_INFO[visualization.id],
        };

        chartExportInfoRow.push(chartExportInfo);
    });
    return chartExportInfoRow;
};

// simple hash function, not suitable for cryptic hashing
// copied from: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
export const hashCode = (inputString: string): number => {
    let hash = 0;
    for (let i = 0; i < inputString.length; i += 1) {
        hash = (Math.imul(31, hash) + inputString.charCodeAt(i)) | 0;
    }
    return hash;
};

// Point to key mapper
export const getKeyFromPointProfileSelection = (
    { lat, lng }: Point,
    profile: Profile,
    selection: number[],
): string =>
    `${lat.toFixed(5)}-${lng.toFixed(5)}-${profile}-${selection.join(',')}`;

export const getIndexedDBKeyForSelfStoragePointInfoReport = (
    { lat, lng }: Point,
    profile: Profile,
    selection: number[],
    pointType: SelfStorage.PointType,
): string =>
    `${lat.toFixed(5)}-${lng.toFixed(5)}-${profile}-${selection.join(
        ',',
    )}-${pointType}`;

export const areObjectsDeepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    return (
        keys1.length === keys2.length && // Same number of keys
        keys1.every(key => keys2.includes(key) && areObjectsDeepEqual(obj1[key], obj2[key])) // Recursive check
    );
}
