import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import getQueryParams from '../helpers/getQueryParams';

import { SiteAnalysis } from '../types';

type Props = {
    q:
        | 'executive-summary'
        | 'executive-summary-detailed'
        | 'demography'
        | 'opportunity'
        | 'rate-trends'
        | 'rental-comps'
        | 'selfstorage-facility'
        | 'housing-start'
        | 'raw-facility-pricing';
    selection?: number[];
    enabled?: boolean;
};

const useOlapSiteAnalysis = ({ q, selection = undefined, enabled = true }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);

    const [data, setData] = useState<null | SiteAnalysis[]>(null);

    const location = useLocation().search;

    // Memoize the selection to avoid triggering `useEffect` unnecessarily
    const memoizedSelection = useMemo(() => JSON.stringify(selection), [
        selection,
    ]);

    useEffect(() => {
        if (!enabled) {
            // If the hook is disabled, reset the state and return early
            setIsLoading(false);
            setData(null);
            setError(null);
            return;
        }

        let isSubscribed = true;
        // on route location change set default values
        setIsLoading(true);
        setError(null);

        const fetchData = async () => {
            try {
                const {
                    point,
                    profile,
                    selection: querySelection,
                } = getQueryParams(location);

                const selectedValues = memoizedSelection
                    ? JSON.parse(memoizedSelection)
                    : querySelection;

                let data: SiteAnalysis[] = [];

                if (selectedValues && selectedValues.length > 0) {
                    for (const selectionValue of selectedValues) {
                        const response = await fetch(
                            `${process.env.REACT_APP_URL_BACKEND_RAILS}/api/v1/data/site-analysis/single?lat=${point.lat}&lng=${point.lng}&q=${q}&profile=${profile}&profile-value=${selectionValue}`,
                            {
                                credentials: 'include',
                            },
                        );
                        if (!response.ok) {
                            throw new Error('Problem fetching data');
                        }

                        const siteAnalysis = await response.json();
                        data.push({
                            selection: selectionValue,
                            contour: siteAnalysis.contour,
                            data: siteAnalysis.data,
                        });
                    }
                }
                if (isSubscribed) {
                    setData(data);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isSubscribed) {
                    if ((error as any).hasOwnProperty('message')) {
                        setError((error as any).message);
                    } else {
                        setError('Error while fetching OLAP data');
                    }

                    setIsLoading(false);
                }
            }
        };

        fetchData();
        return () => {
            isSubscribed = false;
        };
    }, [location, q, memoizedSelection, enabled]);

    return { isLoading, error, data };
};

export default useOlapSiteAnalysis;
