import { User } from '../types';

export const hasValidSubscription = (user: User) => {
    return (
        user.licenses.hasTractIQSelfStorageSubscription ||
        user.licenses.isTrialingTractIQSelfStorage
    );
};

export const hasFeatureToggle = (
    user: User,
    featureId: SubscriptionProducts.ApplicationAccessConfigurationOptions,
) => {
    return user.licenses.activeSubscriptionProducts.some((product) => {
        return product.configuration.features?.includes(featureId);
    });
};

export namespace SubscriptionProducts {
    export enum ApplicationAccess {
        TRACTIQ_SELF_STORAGE = 'ACCESS__TRACTIQ_SELFSTORAGE',
    }

    export enum ApplicationAccessConfigurationOptions {
        ENABLE_RAW_DATA_EXPORT = 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_RAW_DATA_EXPORT',
        ENABLE_SITE_ANALYSIS_API = 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_SITE_ANALYSIS_API',
    }

    export enum DataPackages {
        TRACTIQ_SELF_STORAGE_BASE = 'DATA_PACKAGE__TRACTIQ_SELFSTORAGE_BASE',
        PROPRIETARY_SINGLE_LOCATION_REPORT = 'DATA_PACKAGE__PROPRIETARY_SINGLE_LOCATION_REPORT',
    }
}
