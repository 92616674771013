import CHART_DATA_INFO from '../enums/DataDescriptions';

const getPopOverContentItem = (id: string) => {
    if (id == null) {
        return '<div></div>';
    }
    const { title, items, tooltipImage } = CHART_DATA_INFO[id];

    // TODO: Create our custom popover component with a more elegant solution
    // This string templating is not a nice readable way, easy to debug
    let popoverContentItem = `<div key="${id}">
                                <div>
                                    <span class="text-start fs-7 text-black opacity-062">
                                        {TITLE}
                                    </span>
                                </div>
                                <ul class="text-start">`.replace('{TITLE}', title);


    for (let i = 0; i < CHART_DATA_INFO[id].items.length; i += 1) {
        const item = items[i];
        popoverContentItem = popoverContentItem
            .concat(
                `<li class="my-12">
                    <div>
                        <span class="fw-bold fs-6 text-black opacity-087">
                            {ITEMTITLE}
                        </span>
                        <span class="fs-6 text-black opacity-087">
                            {DESCRIPTION}
                        </span>
                    </div>
                </li>`,
            )
            .replace('{ITEMTITLE}', item.title)
            .replace('{DESCRIPTION}', item.description);
    }

    popoverContentItem = popoverContentItem.concat('</ul>');

    // Append the image if imageUrl is provided
    if (tooltipImage) {
        popoverContentItem = popoverContentItem.concat(
            `
                <span class="text-start fs-7 text-black opacity-062"> ${tooltipImage.title} </span>
                <div class="text-center py-12">
                    <img src="${tooltipImage.source}" alt="${tooltipImage.title}" class="img-fluid" />
                </div>
            `
        );
    }

    popoverContentItem = popoverContentItem.concat('</div>');

    return popoverContentItem;
};

const getPopOverContent = (visualizationIds: string[]) => {
    const hrule = '<hr class="my-12" />';
    let popoverContent = '';
    for (let i = 0; i < visualizationIds.length; i += 1) {
        popoverContent = popoverContent.concat(
            getPopOverContentItem(visualizationIds[i]),
        );
        if (i !== visualizationIds.length - 1) {
            popoverContent = popoverContent.concat(hrule);
        }
    }
    return popoverContent;
};

export default getPopOverContent;
